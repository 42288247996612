import { render, staticRenderFns } from "./BrandMapping.vue?vue&type=template&id=266cb640&scoped=true&"
import script from "./BrandMapping.vue?vue&type=script&lang=ts&"
export * from "./BrandMapping.vue?vue&type=script&lang=ts&"
import style0 from "./BrandMapping.vue?vue&type=style&index=0&id=266cb640&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "266cb640",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VContainer,VIcon,VRadio,VRadioGroup,VRow,VSimpleTable,VTextField})
