























































import Vue from 'vue'
import Component from 'vue-class-component'
import {vxm} from '@/store'

@Component
export default class BrandMapping extends Vue {
  private isLoading = false
  private data = []
  private isLinked = 0
  private isMapped = 0
  private search = ''

  public mounted(): void {
    this.load()
  }

  get brandCount() {
    return this.items.length
  }

  get productCount() {
    let count = 0
    let items = this.items
    for (let i = 0; i < items.length; i++) {
      count += items[i].count
    }
    return count
  }

  get items() {
    if (this.search) {
      let results = []
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].brandName.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
          results.push(this.data[i])
        } else if (this.data[i].brandCode === this.search) {
          results.push(this.data[i])
        }
      }
      return results
    } else {
      return this.data
    }
  }

  private load() {
    this.isLoading = true
    let url = '/v4/site/integrations/dackia/brands?'
    if (this.isMapped !== -1) {
      url += 'isMapped=' + this.isMapped + '&'
    }
    if (this.isLinked !== -1) {
      url += 'isLinked=' + this.isLinked + '&'
    }
    this.$axios
      .get(url)
      .then((response) => {
        this.data = response.data.data
        console.log('data:', this.data)
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load brands data')
        this.isLoading = false
      })
  }
}
